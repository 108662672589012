function selectService() {
  const serviceInput = document.querySelector('#service');
  const items = document.querySelectorAll('.dropdown-items');
  const sumbit = document.querySelector('.start__form-btn');
  const arrFromLabel = [];

  const addEventListeners = () => {
    document.querySelectorAll('.dropdown-items').forEach((item) => {
      item.addEventListener('click', (e) => {
        const menu = e.target.dataset.menu;

        if (arrFromLabel.includes(menu)) {
          // Удалить элемент, если он уже есть в массиве
          const index = arrFromLabel.indexOf(menu);
          arrFromLabel.splice(index, 1);
          serviceInput.value = '';
        } else {
          // Добавить элемент, если его нет в массиве
          arrFromLabel.push(menu);
        }
        const stringFromArr = arrFromLabel.join(',');
        serviceInput.value = stringFromArr;
      });
    });
  };
  addEventListeners();
}

export { selectService };
