function accordion() {
  const li = document.querySelectorAll('.asked__list-item');

  if (li != 0) {
    li.forEach((element) => {
      const span = element.querySelector('.asked__list-title span');

      element.addEventListener('click', (event) => {
        element.classList.toggle('_active');
        span.classList.toggle('_active');
      });
    });
  }
}

export { accordion };

function accordionTesti() {
  const btn = document.querySelector('.testimonials__bottom-show');
  const items = document.querySelectorAll('.testimonials__item');
  const block = document.querySelector('.testimonials__blocks');
  if (btn) {
    btn.addEventListener('click', () => {
      btn.classList.add('hide');
      block.classList.add('_active');
      items.forEach((item) => {
        item.classList.add('_active');
      });
    });
  }
}
export { accordionTesti };

function accordionDo() {
  const showTitle = document.querySelectorAll('.show-item__list-title');

  showTitle.forEach((title) => {
    const li = title.parentElement;
    const text = li.querySelector('.show-item__list-subtitle');
    li.addEventListener('click', () => {
      text.classList.toggle('show_active');
      li.classList.toggle('_active');
    });
  });
}

export { accordionDo };
