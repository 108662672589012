const onSuccess = (form, data) => {
  const $modalSuccess = document.querySelector('#success-modal');
  const $modalError = document.querySelector('#modal-error');

  if (data === 1) {
    $modalSuccess && $modalSuccess.classList.add('show');
  } else {
    $modalError && $modalError.classList.add('show');
  }

  console.log('Fetch is success');
  console.log(data);

  form.resetForm();
};

const onError = (form, error) => {
  const $modalError = document.querySelector('#modal-error');
  $modalError && $modalError.classList.add('show');

  console.log('Fetch with error');
  console.log(error);
};

const onFinally = () => {
  console.log('Fetch anyway ended');
};

function onSubmit(event) {
  event.preventDefault();
  this.isValid = true;

  const action = this.$form.getAttribute('action');
  const method = this.$form.getAttribute('method');
  const body = new FormData(this.$form);

  this.invalidFields = [];
  this.fields.forEach((field) => {
    field.validate();
    field.setValidStatus();

    if (!field.isValid) {
      this.isValid = false;
      this.invalidFields.push(field);
    }
  });



  console.log(this.isValid);
  console.log(this.invalidFields);

  if (this.isValid) {
    const fetch_options = { method };

    if (method.toLowerCase() !== 'GET') {
      fetch_options.body = body;
    }

    fetch(action, fetch_options)
      .then((data) => data.json())
      .then((data) => onSuccess(this, data))
      .catch((error) => onError(this, error));
  }
}

export { onSubmit };
