function dropDown() {
  const dropdownBtn = document.querySelector('.dropdown-btn');
  const dropdownMenu = document.querySelector('.dropdown-menu');
  const selectedItems = [];
  if (dropdownBtn) {
    dropdownBtn.addEventListener('click', function (e) {
      e.preventDefault();

      dropdownMenu.classList.toggle('show-drop');
      dropdownBtn.classList.toggle('open'); // Добавляем/удаляем класс .open
      e.stopPropagation();
    });

    dropdownMenu.addEventListener('change', function (event) {
      const selectedMenuItem = event.target;
      if (selectedMenuItem.tagName === 'INPUT' && selectedMenuItem.type === 'checkbox') {
        const labelText = selectedMenuItem.nextElementSibling.textContent;
        const isChecked = selectedMenuItem.checked;
        const label = selectedMenuItem.nextElementSibling;
        label.classList.toggle('_active');
        // labels.forEach(label => {
        //   label.classList.toggle("_active")
        // })
        if (isChecked) {
          if (!selectedItems.includes(labelText)) {
            selectedItems.push(labelText);
          }
        } else {
          const index = selectedItems.indexOf(labelText);
          if (index !== -1) {
            selectedItems.splice(index, 1);
          }
        }

        updateButtonText();
      }

      event.stopPropagation();
    });

    document.addEventListener('click', function (event) {
      // Если клик произошел вне области меню, закрываем меню и удаляем класс .open
      if (!dropdownMenu.contains(event.target)) {
        dropdownMenu.classList.remove('show-drop');
        dropdownBtn.classList.remove('open');
      }
    });
    function updateButtonText() {
      if (selectedItems.length > 0) {
        dropdownBtn.textContent = selectedItems.join(', ');
      } else {
        dropdownBtn.innerHTML = `   <span>Click & Select A Service <span class="required">*</span></span>
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="12" viewBox="0 0 21 12" fill="none">
        <path d="M1.5 1.5L10.5 10.5L19.5 1.5" stroke="#1B1818" stroke-width="2"/>
        </svg>`;
      }
    }
  }
}

export { dropDown };
