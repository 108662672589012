import AOS from 'aos';
import { burger } from './modules/burger';
import { swiper, aboutSwiper } from './modules/swiper';
import { accordion, accordionDo } from './modules/accordeon';
import { mobileTabs } from './modules/mobileTabs';
import { dropDown } from './modules/dropdown';
import { modal } from './components/modal';
import { inputs } from './modules/inputs.js';
import { switchText } from './modules/switchText.js';
import { testimo4 } from './modules/swiper';
import { accordionTesti } from './modules/accordeon';
import { heroVideo } from './modules/video';
import { formValidation } from './components/form-validation';
import { updateDate } from './modules/footer';
import { selectService } from './modules/selectService';

window.addEventListener('load', () => {
  burger();
  swiper();
  accordion();
  aboutSwiper();
  mobileTabs();
  dropDown();
  AOS.init();
  // Modals
  modal();
  inputs();
  switchText();
  testimo4();
  accordionTesti();
  heroVideo();
  formValidation();
  updateDate();
  selectService();
  accordionDo();
});
