function mobileTabs() {
  const cards = document.querySelectorAll('.do__mobile-wrapper');
  cards.forEach((card) => {
    card.addEventListener('click', () => {
      const cardBottom = card.querySelector('.do__card-bottom');
      const overview = card.querySelector('.do__overview');
      const card1 = card.querySelector('.do__card');
      cardBottom.classList.toggle('_active');
      overview.classList.toggle('_active');
      card.classList.toggle('_active');
      card1.classList.toggle('_active');
    });
  });
}

export { mobileTabs };
