import Swiper from 'swiper/bundle';

function testimo4() {
  const swiper3 = new Swiper('.testimonials__swiper', {
    spaceBetween: 23,
    centeredSlides: true,
    slidesPerView: 1,
    autoHeight: true
  });
}

export { testimo4 };

function swiper() {
  if (window.innerWidth > 992) {
    const cards = document.querySelectorAll('.do__card-pc');
    const top = document.querySelector('.do__top');
    cards.forEach((element) => {
      const btn = element.querySelector('.do__more');
      btn.addEventListener('click', () => {
        const sliderBtn = btn.getAttribute('data-slider-id');
        console.log(sliderBtn);
        const slide = document.getElementById(sliderBtn);
        const slides = document.querySelectorAll('.do__swiper-slide');
        slides.forEach((s) => {
          s.classList.remove('_active');
        });
        cards.forEach((s) => {
          s.classList.remove('_active');
        });
        element.classList.add('_active');
        slide.classList.add('_active');
        top.classList.add('_margin');
      });
    });
  }
}

export { swiper };

function aboutSwiper() {
  const swiper = new Swiper('.about__swiper', {
    spaceBetween: 40,
    navigation: {
      nextEl: '.about__arrow-button-next',
      prevEl: '.about__arrow-button-prev'
    },

    scrollbar: {
      el: '.swiper-pagination', // Укажите класс элемента панели прокрутки
      hide: false // Показать панель прокрутки всегда
    }
  });
}

export { aboutSwiper };
