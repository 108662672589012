function burger() {
  const burger = document.querySelector('.icon-menu ');
  const burgerMenu = document.querySelector('.menu');

  const body = document.querySelector('body');

  burger.addEventListener('click', () => {
    burgerMenu.classList.toggle('active');
    burger.classList.toggle('_active');
    body.classList.toggle('scroll-locked');
  });

  const menuListLinks = document.querySelectorAll('.menu__list-link');
  menuListLinks.forEach((link) => {
    link.addEventListener('click', () => {
      burgerMenu.classList.remove('active');
      burger.classList.remove('_active');
      body.classList.remove('scroll-locked');
    });
  });
}

export { burger };
