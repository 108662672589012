// script.js
function inputs() {
  const popupBtn = document.querySelectorAll('.modal__close');
  const inputElements = document.querySelectorAll('.start__input');
  const dropdown = document.querySelector('.dropdown-btn');
  const serviceInput = document.querySelector('#service');
  popupBtn.forEach((btn) => {
    btn.addEventListener('click', () => {
      inputElements.forEach((inputElement) => {
        const labelElement = inputElement.previousElementSibling;
        labelElement.style.opacity = '1';
        dropdown.innerHTML = 'Click & Select A Service';
        serviceInput.value = '';
      });
    });
  });

  inputElements.forEach((inputElement) => {
    const labelElement = inputElement.previousElementSibling;
    const inputWrapper = inputElement.closest('.start__wrapper');
    let hasContentBeforeBlur = false; // Флаг, указывающий, был ли контент до потери фокуса

    inputElement.addEventListener('focus', function () {
      inputWrapper.classList.add('focused');
      labelElement.style.opacity = '0';
      hasContentBeforeBlur = hasContent();
      toggleLabelVisibility();
    });

    // Функция для проверки наличия контента в .start__wrapper и скрытия/отображения label
    function toggleLabelVisibility() {
      const hasContentNow = hasContent();
      // labelElement.style.opacity = hasContentNow ? '0' : '1';

      // Если был контент до фокуса, но стал пустым после потери фокуса, возвращаем текст label
      if (hasContentBeforeBlur && !hasContentNow) {
        labelElement.style.opacity = '1';
      }
    }

    function hasContent() {
      const inputElements = Array.from(inputWrapper.querySelectorAll('.start__input'));
      return inputElements.some((inputElement) => inputElement.value.trim().length > 0);
    }

    inputElement.addEventListener('blur', function () {
      inputWrapper.classList.remove('focused');
      const hasContentAfterBlur = hasContent();
      if (!hasContentAfterBlur) {
        labelElement.style.opacity = '1';
      } else {
        labelElement.style.opacity = '0';
      }
    });
  });
}

export { inputs };
