import { minmaxLength } from './minmax-length';

function name() {
  const regExp = new RegExp('[\\[\\]{}!"£$%^&*()-=_+@~\'#;:<>?\\/]')
  const isEmpty = this.$input.value === '';
  const { isMinMaxLengthValid, minLength, maxLength, currentLength } = minmaxLength(this.$input);

  if (isEmpty && this.$error) {
    this.isValid = false;
    return;
  }

  if (this.$input.value.match(regExp) && this.$error) {
    this.isValid = false;
    return;
  }

  if (!isMinMaxLengthValid) {
    this.isValid = false;
    return;
  }

  this.isValid = true;
}

export { name };
